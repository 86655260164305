.prayer-times {
    padding: 20px;
}

.prayer-times h2 {
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 10px;
}

.prayer-times table {
    width: 100%;
    border-collapse: collapse;
}

.prayer-times th, .prayer-times td {
    border: 1px solid #ddd;
    padding: 8px;
}

.prayer-times th {
    background-color: #f2f2f2;
    text-align: left;
}

.prayer-times-image {
    width: 50%;
    height: auto;
    display: block;
    margin: 20px auto;
}