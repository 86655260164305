.banner {
    text-align: center;
    padding: 20px; /* Optional: Add some padding around the banner */
}

.banner-image {
    width: 10%; /* Change this value to your desired width, e.g., 50%, 500px, etc. */
    height: auto; /* Maintain aspect ratio */
    max-width: 50%; /* Ensure it doesn't overflow the container */
    display: block; /* Center the image */
    margin: 0 auto; /* Center the image */
}
