.news {
    padding: 20px;
}

.news h2 {
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 10px;
}

.news ul {
    list-style-type: none;
    padding: 0;
}

.news li {
    padding: 10px 0;
}
