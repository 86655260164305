.contact {
    padding: 20px;
}

.contact h2 {
    border-bottom: 2px solid #4CAF50;
    padding-bottom: 10px;
}

.contact ul {
    list-style-type: none;
    padding: 0;
}

.contact li {
    padding: 10px 0;
}
